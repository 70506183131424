import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Root from "./root";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthKeycloak from "./utils/AuthKeycloak";
import { configure } from "./services/http";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ptBR } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";

dayjs.locale("pt-br");

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  ptBR // use 'de' locale for UI texts (start, next month, ...)
);

const render = () =>
  ReactDOM.render(
    <React.StrictMode>
      <ToastContainer />
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <Root />
        </LocalizationProvider>
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );

// ReactDOM.render(
//   <React.StrictMode>
//     <ToastContainer />
//     <Root />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

AuthKeycloak.initKeycloak(render);
configure();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
