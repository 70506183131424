export function iscpf(cpf) {
  if (!cpf) return false;
  cpf = cpf.replace(/[^\d]+/g, "");
  // verificando se tem a quantidade certa de caracter e se não tem todos caracteres iguais
  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;
  let soma = 0,
    resto;
  for (var i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (var i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(10, 11))) return false;
  return true;
}

export function handleMaskCPF(event) {
  const x = event.target.value
    .replace(/\D+/g, "")
    .match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
  event.target.value =
    `${x[1]}` +
    (x[2] ? `.${x[2]}` : ``) +
    (x[3] ? `.${x[3]}` : ``) +
    (x[4] ? `-${x[4]}` : ``);
}

//1111-1/11
export function handleMaskCNPJ(e) {
  const [cpnj] = e.target.value
    .replace(/\D+/g, "")
    .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
  e.target.value = cpnj
    .replace(/[^0-9]/g, "")
    .replace(/^(\d{2})(\d)/, "$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    .replace(/\.(\d{3})(\d)/, ".$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2");
}

export function handleMaskCNAE(e) {
  const [all, one, two, three] = e.target.value
    .replace(/\D+/g, "")
    .match(/(\d{0,4})(\d{0,1})(\d{0,2})/);
  if (all.length === 7) return (e.target.value = `${one}-${two}/${three}`);
  e.target.value = all;
}

//Formatar Telefone
export function handleMaskTelefone(event) {
  const x = event.target.value
    .replace(/\D+/g, "")
    .match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
  event.target.value = !x[2]
    ? x[1]
    : `(${x[1]}) ${x[2]}` + (x[3] ? `-${x[3]}` : ``);
}

//formatar CEP
export function handleMaskCEP(event) {
  const x = event.target.value.replace(/\D+/g, "").match(/(\d{0,5})(\d{0,3})/);
  event.target.value = `${x[1]}` + (x[2] ? `-${x[2]}` : ``);
}

export function removeSpecialCharacters(text) {
  if (typeof text === "string") {
    return text.replace(/[^a-zA-Z0-9]/g, "");
  }
}
