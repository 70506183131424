import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import "./singup.modules.scss";
// import '../../../views/minha-empresa/minha-empresa.scss'
import { useFormik } from "formik";
import api, { apiCep } from "../../../services/http";
import Select from "react-select";
import {
  handleMaskCPF,
  handleMaskCNPJ,
  handleMaskCNAE,
  handleMaskCEP,
  handleMaskTelefone,
} from "../../../utils/formatting";
import { toast } from "react-toastify";

// TODO: refactor used scss, class bootstrap, divide responsibilities by function
/*
 * Setor e segmento chamada
 * GET
 * http://dev.api.focus.apps.sebraemg.com.br/api/register/get-sectors
 * Agronegócio
 * GET
 *  http://dev.api.focus.apps.sebraemg.com.br/api/register/:id/get-segments
 * */

const states = [
  { nome: "Acre", sigla: "AC" },
  { nome: "Alagoas", sigla: "AL" },
  { nome: "Amapá", sigla: "AP" },
  { nome: "Amazonas", sigla: "AM" },
  { nome: "Bahia", sigla: "BA" },
  { nome: "Ceará", sigla: "CE" },
  { nome: "Distrito Federal", sigla: "DF" },
  { nome: "Espírito Santo", sigla: "ES" },
  { nome: "Goiás", sigla: "GO" },
  { nome: "Maranhão", sigla: "MA" },
  { nome: "Mato Grosso", sigla: "MT" },
  { nome: "Mato Grosso do Sul", sigla: "MS" },
  { nome: "Minas Gerais", sigla: "MG" },
  { nome: "Pará", sigla: "PA" },
  { nome: "Paraíba", sigla: "PB" },
  { nome: "Paraná", sigla: "PR" },
  { nome: "Pernambuco", sigla: "PE" },
  { nome: "Piauí", sigla: "PI" },
  { nome: "Rio de Janeiro", sigla: "RJ" },
  { nome: "Rio Grande do Norte", sigla: "RN" },
  { nome: "Rio Grande do Sul", sigla: "RS" },
  { nome: "Rondônia", sigla: "RO" },
  { nome: "Roraima", sigla: "RR" },
  { nome: "Santa Catarina", sigla: "SC" },
  { nome: "São Paulo", sigla: "SP" },
  { nome: "Sergipe", sigla: "SE" },
  { nome: "Tocantins", sigla: "TO" },
];

export default function SingUp() {
  let [sectors, setSectors] = useState([]),
    [followUp, setFollowUp] = useState([]),
    [color, setColor] = useState({}),
    [loading, setLoading] = useState(false),
    [followUpSelected, setFollowUpSelected] = useState([]),
    {
      values,
      handleChange,
      handleSubmit,
      setFieldValue,
      setValues,
      resetForm,
    } = useFormik({
      initialValues: {
        fantasyName: "",
        document: "",
        socialName: "",
        cnae: "",
        size: "",
        opening_date: "",
        responsibleFirstName: "",
        responsibleLastName: "",
        responsibleDocument: "",
        zip: "",
        street: "",
        number: "",
        complement: "",
        neighborhood: "",
        password: "",
        password_confirmation: "",
        email: "",
        city: "",
        sector: "",
        segments: [],
      },

      onSubmit: async (values) => {
        const segmentsSeleted = followUpSelected.map(
          ({ segments }) => segments
        );
        const createAccount = {
          ...values,
          segments: segmentsSeleted,
        };
        let waning = null;
        let existFieldsNull = false;
        let isPasswordDifferent = false;

        Object.values(createAccount).map((value, index) => {
          if ((!value || value.length === 0) && index != 12) {
            waning = "Preencha todos os campos";
            existFieldsNull = true;
          }
        });

        if (existFieldsNull) {
          toast.warn(waning, {
            position: "top-center",
            theme: "colored",
          });
          return;
        }

        if (values.password != values.password_confirmation) {
          isPasswordDifferent = true;
          waning = "As senhas devem ser iguais";
        }

        if (isPasswordDifferent) {
          toast.warn(waning, {
            position: "top-center",
            theme: "colored",
          });
          return;
        }

        try {
          setLoading(true);
          const id = toast.loading(
            "Aguarde um momento enquanto sua conta está sendo criada!"
          );
          await api.post("/register/company", { ...createAccount });
          resetForm();
          toast.update(id, {
            render:
              "Confirmação de e-mail necessária! Foi enviado um email para ativar a sua conta!",
            type: "success",
            position: "top-center",
            theme: "colored",
            isLoading: false,
            autoClose: true,
            closeButton: true,
            draggable: true,
            onClose: () => {
              setLoading(false);
              window.location.href = "/";
            },
          });
        } catch (e) {
          setLoading(false);
          const { message } = e.response.data;
          if (
            e.response.data.error &&
            e.response.data.error.message.document.length > 0
          ) {
            toast.warn(e.response.data.error.message.document[0], {
              position: "top-center",
              theme: "colored",
            });
            return;
          } else if (message.match(/Integrity constraint violation/i)) {
            toast.error("email ou CNPJ já cadastrado no sistema", {
              position: "top-center",
              theme: "colored",
            });
            return;
          }
          toast.error("Verifique os dados e tente novamente", {
            position: "top-center",
            theme: "colored",
          });
        }
      },
    });

  useEffect(() => {
    (async () => {
      const { data } = await api.get("/register/get-sectors");
      setSectors(data);
    })();
  }, []);

  const handleSector = async (id) => {
    setColor({ id: id });
    const { data } = await api.get(`/register/${id}/get-segments`);
    setFieldValue("sector", Number(id));
    setFollowUp(data);
  };

  const handleViaCep = async () => {
    const { zip } = values;
    if (zip.length < 8) return;
    const { data } = await apiCep.get(`/${zip}/json`);
    await setValues({
      ...values,
      neighborhood: data.bairro,
      street: data.logradouro,
      city: data.localidade,
      state: data.uf,
    });
  };

  return (
    <div className={"page-area"}>
      <div className="sidebar-title-container">
        <p className="sidebar-title">Cadastro</p>
      </div>
      <Container>
        <div className={"title-group-fields"}>
          <p>Dados da Empresa</p>
        </div>
        <form onSubmit={handleSubmit} className={"area-fields"}>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Nome Fantasia"
                  style={{ width: "90%" }}
                >
                  <Form.Control
                    autoComplete={"off"}
                    type="text"
                    name="fantasyName"
                    value={values.fantasyName}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <FloatingLabel
                  controlId="floatingInput"
                  label="CNPJ"
                  style={{ width: "90%" }}
                >
                  <Form.Control
                    autoComplete={"off"}
                    type="text"
                    name="document"
                    value={values.document}
                    onChange={(e) => {
                      handleMaskCNPJ(e);
                      handleChange(e);
                    }}
                  />
                </FloatingLabel>
              </Form.Group>
              <FloatingLabel
                style={{ width: "90%" }}
                controlId="floatingSelect"
                label="Porte da empresa"
              >
                <Form.Select
                  onChange={handleChange}
                  name="size"
                  aria-label="Porte da empresa"
                >
                  <option>Selecione o porte da empresa</option>
                  <option value="MEI">MEI</option>
                  <option value="ME">ME</option>
                  <option value="EPP">EPP</option>
                  <option value="OUTROS">OUTROS</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FloatingLabel controlId="floatingInput" label="Razão social">
                    <Form.Control
                      autoComplete={"off"}
                      type="text"
                      name="socialName"
                      value={values.socialName}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Row>
                <Col sm={7}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <FloatingLabel controlId="floatingInput" label="CNAE">
                      <Form.Control
                        autoComplete={"off"}
                        type="text"
                        mask={"1111-1/11"}
                        name="cnae"
                        value={values.cnae}
                        onChange={(e) => {
                          handleMaskCNAE(e);
                          handleChange(e);
                        }}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={5}>
                  <Form.Group
                    className="mb-3 d-flex flex-nowrap"
                    controlId="formBasicEmail"
                  >
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Data de abertura"
                    >
                      <Form.Control
                        autoComplete={"off"}
                        type="date"
                        name="opening_date"
                        value={values.opening_date}
                        onChange={handleChange}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <div className={"setor-select mb-3 mt-3"}>
              <p>Setor</p>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {sectors.map(({ id, name }) => (
                  <div
                    key={id}
                    className={`texto-base centralizar setor-button ${
                      color.id === id && "setor-button-selected"
                    }`}
                    onClick={() => handleSector(id)}
                  >
                    {name}
                  </div>
                ))}
              </div>
            </div>
            <div className={"area-select-segmento"}>
              <p>Segmento</p>
              <Select
                isMulti
                inputId={"segmento"}
                isDisabled={!!!followUp.length}
                onChange={async (value) => {
                  setFollowUpSelected(value);
                }}
                value={followUpSelected}
                name={"segments"}
                required
                options={followUp.map((segments) => ({
                  value: segments.id,
                  label: segments.name,
                  segments,
                }))}
              />
            </div>
          </Row>

          <div className={"title-group-fields"}>
            <p>Dados Pessoais</p>
          </div>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Nome"
                  style={{ width: "80%" }}
                >
                  <Form.Control
                    autoComplete={"off"}
                    type="text"
                    name="responsibleFirstName"
                    value={values.responsibleFirstName}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Sobrenome"
                  style={{ width: "80%" }}
                >
                  <Form.Control
                    autoComplete={"off"}
                    type="text"
                    name="responsibleLastName"
                    value={values.responsibleLastName}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <FloatingLabel
                  controlId="floatingInput"
                  label="CPF"
                  style={{ width: "50%" }}
                >
                  <Form.Control
                    autoComplete={"off"}
                    type="text"
                    name="responsibleDocument"
                    value={values.responsibleDocument}
                    onChange={(e) => {
                      handleMaskCPF(e);
                      handleChange(e);
                    }}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Telefone"
                  style={{ width: "80%" }}
                >
                  <Form.Control
                    autoComplete={"off"}
                    type="text"
                    name="responsiblePhone"
                    value={values.responsiblePhone}
                    onChange={(e) => {
                      handleMaskTelefone(e);
                      handleChange(e);
                    }}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <h3>Endereço</h3>
          <Row>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <FloatingLabel
                controlId="floatingInput"
                label="CEP"
                style={{ width: "20%" }}
              >
                <Form.Control
                  autoComplete={"off"}
                  type="text"
                  name="zip"
                  value={values.zip}
                  onBlur={handleViaCep}
                  onChange={(e) => {
                    handleMaskCEP(e);
                    handleChange(e);
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rua"
                  style={{ width: "90%" }}
                >
                  <Form.Control
                    autoComplete={"off"}
                    // disabled={true}
                    type="text"
                    name="street"
                    value={values.street}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>

            <Col md={2}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <FloatingLabel controlId="floatingInput" label="Número">
                  <Form.Control
                    autoComplete={"off"}
                    type="text"
                    name="number"
                    value={values.number}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Complemento"
                  style={{ width: "80%" }}
                >
                  <Form.Control
                    autoComplete={"off"}
                    type="text"
                    name="complement"
                    value={values.complement}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Bairro"
                  style={{ width: "90%" }}
                >
                  <Form.Control
                    autoComplete={"off"}
                    // disabled={true}
                    type="text"
                    name="neighborhood"
                    defaultValue={values.neighborhood}
                    value={values.neighborhood}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Cidade"
                  style={{ width: "90%" }}
                >
                  <Form.Control
                    autoComplete={"off"}
                    // disabled={true}
                    type="text"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>

            <Col md={2}>
              <FloatingLabel controlId="floatingSelect" label="Estado">
                <Form.Select
                  // disabled={true}
                  aria-label="Floating label select example"
                >
                  <option selected={true}>{values.state}</option>
                  {states.map((e) => (
                    <option>{e.sigla}</option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>

          <div className={"title-group-fields"}>
            <p>Acesso</p>
          </div>
          <Row>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <FloatingLabel
                controlId="floatingInput"
                label="Email"
                style={{ width: "49%" }}
              >
                <Form.Control
                  autoComplete={"off"}
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <FloatingLabel controlId="floatingInput" label="Senha">
                  <Form.Control
                    autoComplete={"off"}
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Confirme a senha"
                >
                  <Form.Control
                    autoComplete={"off"}
                    type="password"
                    name="password_confirmation"
                    value={values.password_confirmation}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <div className={"footer-buttons"}>
            <Button variant="primary" type="submit" disabled={loading}>
              Entrar
            </Button>
            <Button
              variant="light"
              onClick={() => {
                window.location.href = "/";
              }}
            >
              Sair
            </Button>
          </div>
        </form>
      </Container>
    </div>
  );
}
