import axios from "axios";
import AuthKeycloak from "../utils/AuthKeycloak";

const api = axios.create({
  baseURL: process.env.REACT_APP_LINK_API,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
export const configure = () => {
  api.interceptors.request.use(
    function (successfulReq) {
      // document.body.classList.add('loading-indicator');
      if (AuthKeycloak.isLoggedIn()) {
        const cb = () => {
          successfulReq.headers.Authorization = `Bearer ${AuthKeycloak.token()}`;
          return Promise.resolve(successfulReq);
        };
        return AuthKeycloak.updateToken(cb);
      }

      return successfulReq;
    },
    function (error) {
      // document.body.classList.remove('loading-indicator');
      return Promise.reject(error);
    }
  );
  api.interceptors.response.use(
    (response) => {
      // document.body.classList.remove('loading-indicator');
      return response;
    },
    (error) => {
      // document.body.classList.remove('loading-indicator');
      const status = error.response ? error.response.status : null;

      if (status === 401) {
        error.config.headers.Authorization = `Bearer ${AuthKeycloak.token()}`;
        error.config.baseURL = process.env.REACT_APP_LINK_API;
        return api.request(error.config);
      }

      return Promise.reject(error);
    }
  );
};

export const apiCep = axios.create({
  baseURL: process.env.REACT_APP_VIA_CEP,
});

export default api;
