import React, { Suspense, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./base.scss";
import "./landing/App.css";
import { HashRouter, Switch, Route } from "react-router-dom";
import SingUp from "./landing/components/singUp";
import SharedContext from "./SharedContext";

//Dashboard
const NavBar = React.lazy(() => import("./components/nav-bar/navbar"));
const SideBar = React.lazy(() => import("./components/side-bar/sidebar"));
const View = React.lazy(() => import("./components/view/view"));
const Conteudos = React.lazy(() => import("./views/conteudos/conteudos"));
const Conteudo = React.lazy(() => import("./views/view-conteudo/view-conteudo"));
const Func = React.lazy(() => import("./views/funcionalidades/funcionalidades"));
const Diag = React.lazy(() => import("./views/diagnosticos/diagnosticos"));
const FooterDashboard = React.lazy(() => import("./components/footer-dashboard/footer"));

//Landing
const Home = React.lazy(() => import("./landing/components/home/home"));
const NewsContent = React.lazy(() => import("./landing/components/news-content/newsContent"));
const FeedNews = React.lazy(() => import("./landing/components/feed-news/feedNews"));
const Footer = React.lazy(() => import("./landing/components/footer/footer"));
const Navbar = React.lazy(() => import("./landing/components/navbar/navbar"));
const Info = React.lazy(() => import("./landing/components/info/info"));
const ResetPassword = React.lazy(() =>
  import("./landing/components/reset-password/reset-password")
);
const Aviso = React.lazy(() => import("./landing/components/aviso/aviso"));

const currentYear =
  new Date().getMonth() == 0 ? new Date().getFullYear() - 1 : new Date().getFullYear();
const currentMonth = new Date().getMonth() == 0 ? 11 : new Date().getMonth() - 1;
const currentDate = new Date(currentYear, currentMonth);
const company = JSON.parse(localStorage.getItem("company"));

function Root() {
  const [shared, setShared] = useState({
    index: sessionStorage.getItem("index") || 0,
    selectedDate: currentDate,
    sidebar: true,
    content: false,
    informal: company?.document_type == 0,
  });
  const [notification, setNotification] = useState(0);

  const removeNotification = () => {
    if (notification > 0) {
      setNotification(notification - 1);
    }
  };

  const setDate = (date) => {
    shared.selectedDate = date;
    setShared({ ...shared });
  };

  const setActiveIndex = (index) => {
    const company = JSON.parse(localStorage.getItem("company"));
    if (company && Object.keys(company).length === 0 && index != 7) {
      return;
    }
    sessionStorage.setItem("index", index);
    shared.index = index;
    setShared({ ...shared });
  };

  const setContent = (value) => {
    shared.content = value;
    setShared({ ...shared });
  };

  const toggleSidebar = (value) => {
    shared.sidebar = value;
    setShared({ ...shared });
  };

  const setInformal = (value) => {
    shared.informal = value == 0;
    setShared({ ...shared });
  };

  return (
    <div style={{ backgroundColor: "#F5F5F5" }}>
      <HashRouter>
        <Suspense fallback={<div></div>}>
          <SharedContext.Provider value={shared}>
            <Switch>
              <Route path="/app/conteudos">
                <NavBar notification={notification} setNotification={setNotification} />
                <Conteudos setContent={setContent} />
                <FooterDashboard />
              </Route>
              <Route path="/app/conteudo">
                <NavBar notification={notification} setNotification={setNotification} />
                <Conteudo setContent={setContent} />
                <FooterDashboard />
              </Route>
              <Route path="/app/functionality">
                <NavBar notification={notification} setNotification={setNotification} />
                <Func />
                <FooterDashboard />
              </Route>
              <Route path="/app/diagnosticos">
                <NavBar notification={notification} setNotification={setNotification} />
                <Diag setNotification={removeNotification} setContent={setContent} />
                <FooterDashboard />
              </Route>
              <Route path="/app">
                <NavBar
                  setNotification={setNotification}
                  notification={notification}
                  toggleSidebar={toggleSidebar}
                />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <SideBar toggleSidebar={toggleSidebar} />
                  <View setDate={setDate} setInformal={setInformal} />
                </div>
              </Route>

              <Route path="/aviso">
                <Aviso />
              </Route>
              <Route path="/news">
                <Navbar />
                <FeedNews />
                <Footer />
              </Route>
              <Route path="/info">
                <Navbar />
                <Info />
                <Footer />
              </Route>
              <Route path="/signUp">
                <Navbar />
                <SingUp />
              </Route>
              <Route path="/response-password-reset">
                <Navbar />
                <ResetPassword />
              </Route>
              <Route path="/functionality">
                <NavBar />
                <Info />
                <Footer />
              </Route>
              <Route path="/content">
                <Navbar />
                <NewsContent />
                <Footer />
              </Route>
              <Route path="/diagnosticos">
                <NavBar />
                <Info />
                <Footer />
              </Route>
              <Route path="/">
                <Navbar />
                <div style={{ overflowY: "auto" }}>
                  <Home />
                  <Footer />
                </div>
              </Route>
            </Switch>
          </SharedContext.Provider>
        </Suspense>
      </HashRouter>
    </div>
  );
}

export default Root;
